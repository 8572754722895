import React from 'react';
import { CssBaseline, Container, Box } from '@mui/material';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import HowToBuy from './components/HowToBuy';
import Community from './components/Community';
import './App.css';

function App() {
  return (
    <>
      <CssBaseline />
      <Navbar />
      <Box className="app-background" style={{ width: '100%' }}>
        <Container style={{ width: '100%', maxWidth: 'none', padding: 0 }}>
          <Home />
          <About />
          <HowToBuy />
          <Community />
        </Container>
      </Box>
    </>
  );
}

export default App;
