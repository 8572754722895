import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-scroll';

const Navbar = () => {
  return (
    <AppBar position="fixed" style={{ backgroundColor: '#212529' }}>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="h6">
            Real Man
          </Typography>
          <Box>
            <Button color="inherit">
              <Link to="home" smooth={true} duration={500} style={{ color: 'inherit', textDecoration: 'none' }}>
                Home
              </Link>
            </Button>
            <Button color="inherit">
              <Link to="about" smooth={true} duration={500} style={{ color: 'inherit', textDecoration: 'none' }}>
                About
              </Link>
            </Button>
            <Button color="inherit">
              <Link to="how-to-buy" smooth={true} duration={500} style={{ color: 'inherit', textDecoration: 'none' }}>
                How to Buy
              </Link>
            </Button>
            <Button color="inherit">
              <Link to="community" smooth={true} duration={500} style={{ color: 'inherit', textDecoration: 'none' }}>
                Community
              </Link>
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
