import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import homeImage from '../assets/homeImage.jpg';
import '../App.css'; // Import CSS

const Home = () => {
  const handleBuyClick = () => {
    window.open('https://blockchainminer.org/', '_blank');
  };

  return (
    <Box id="home" className="component-background" style={{ width: '100%', maxWidth: 'none' }}>
      <img src={homeImage} alt="Home" style={{ maxWidth: '10%', height: 'auto' }} />
      <Typography variant="h3" gutterBottom>Welcome to Real Man</Typography>
      <Typography variant="body1">
        Inspired by the true men of WWE, Real Man Token ($MAN) aims to bring the values of courage, perseverance, and camaraderie to the crypto world. Join us in building a strong and reliable community.
      </Typography>
      <Button className="buy-button" onClick={handleBuyClick}>
        Buy $MAN
      </Button>
    </Box>
  );
};

export default Home;
