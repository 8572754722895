import React from 'react';
import { Typography, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import '../App.css'; // Import CSS

const Community = () => {
  return (
    <Box id="community" className="component-background">
      <Typography variant="h3" gutterBottom>Join the Real Man Community</Typography>
      <Typography variant="body1" gutterBottom>Follow us on social media to stay updated with the latest news and updates, and connect with fellow warriors:</Typography>
      <List>
        <ListItem button component="a" href="https://blockchainminer.org/">
          <ListItemIcon>
            <TwitterIcon style={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Twitter(X)" style={{ color: '#fff' }} />
        </ListItem>
        <ListItem button component="a" href="https://blockchainminer.org/">
          <ListItemIcon>
            <TelegramIcon style={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Telegram" style={{ color: '#fff' }} />
        </ListItem>
      </List>
    </Box>
  );
};

export default Community;
