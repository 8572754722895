import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import '../App.css'; // Import CSS

const HowToBuy = () => {
  const handleBuyClick = () => {
    window.open('https://blockchainminer.org/', '_blank');
  };

  return (
    <Box id="how-to-buy" className="component-background">
      <Typography variant="h3" gutterBottom>How to Buy $MAN</Typography>
      <Typography variant="body1" gutterBottom>Follow the link below to purchase $MAN tokens and become part of our community of champions:</Typography>
      <Button className="buy-button" onClick={handleBuyClick}>
        Buy $MAN
      </Button>
    </Box>
  );
};

export default HowToBuy;
