import React from 'react';
import { Typography, Box } from '@mui/material';
import '../App.css'; // Import CSS

const About = () => {
  return (
    <Box id="about" className="component-background">
      <Typography variant="h3" gutterBottom>About Real Man</Typography>
      <Typography variant="body1">
        Real Man Token ($MAN) is more than just a cryptocurrency; it's a symbol of strength, resilience, and trust. Inspired by the champions of WWE, $MAN is designed to create a community that values integrity and mutual support. With a total supply of 1 billion tokens, $MAN is here to revolutionize the crypto landscape by embodying the spirit of true warriors.
      </Typography>
    </Box>
  );
};

export default About;
